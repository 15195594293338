<template>
  <li :class="$style.component" @click="closeNav">
    <router-link
      :to="route.meta.url[currentLanguage]"
      :exact-active-class="$style['is-active']"
      :class="$style.link"
    >
      <!-- v-scroll-to="scrollTo" -->
      <div>{{ route.title[currentLanguage] }}</div>
    </router-link>
  </li>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    route: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(['currentLanguage'])
    // scrollTo() {
    //   return {
    //     el: `#${this.route.id}`,
    //     duration: 100,
    //     easing: 'ease'
    //   }
    // }
  },
  methods: {
    closeNav() {
      this.$store.commit('setNavVisibility', false)
    }
  }
}
</script>

<style lang="scss" module>
// .component {
//   @extend %fs-nav;

//   display: inline-block;
//   color: var(--black);
//   text-transform: uppercase;
//   transition: color 450ms ease;

//   &:not(:last-of-type) {
//     padding-right: var(--gutter);
//   }

//   &.is-negative {
//     color: var(--white);
//   }
// }

.link {
  @extend %link-reset;
  @extend %ff-sans;
  @extend %fw-bold;
  @extend %fs-medium;

  // @include focus-default($color: transparent);
}
// .is-active {}
</style>
