<template>
  <div :class="classes.layout">
    <site-header />
    <transition name="t-view" mode="out-in" @before-enter="beforeEnter">
      <keep-alive>
        <slot />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import SiteHeader from '@/components/site-header'
import { mapState } from 'vuex'

export default {
  components: { SiteHeader },
  computed: {
    ...mapState(['navVisible', 'filterVisible']),
    classes() {
      return {
        layout: [
          this.$style.layout,
          this.navVisible ? this.$style.navVisible : '',
          this.filterVisible ? this.$style.filterVisible : ''
        ]
      }
    }
  },
  methods: {
    beforeEnter() {
      this.$root.$emit('trigger-scroll')
    }
  }
}
</script>

<style lang="scss" scoped>
@include t-fade('t-view');
</style>

<style lang="scss" module>
.layout {
  height: 100%;
  transition: color 450ms ease;

  &.navVisible,
  &.filterVisible {
    color: transparent;
  }
}
</style>
