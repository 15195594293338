<template>
  <transition-group name="t-fade">
    <section v-if="navVisible" :class="$style.component" key="section">
      <button type="button" :class="$style.close" @click="closeNav">
        <span :class="$style.label">{{ $t('close') }}</span>
      </button>
      <div :class="$style.content">
        <div :class="$style.infos">
          <router-link to="/" :class="$style.link" @click.native="backToHome">
            <div :class="$style.title" v-html="siteTitle[currentLanguage]" />
            <div :class="$style.claim" v-html="claim[currentLanguage]" />
          </router-link>
          <div :class="$style.contact" v-html="contact[currentLanguage]" />
        </div>

        <!-- <div :class="$style.controls"> -->
        <ul :class="$style.items">
          <nav-bar-item
            v-for="route in visibleRoutes"
            :key="route.id"
            :route="route"
          />
        </ul>
        <!-- <language-switch /> -->
        <!-- </div> -->
      </div>
    </section>

    <button type="button" :class="$style.nav" @click="openNav" key="button">
      <span v-if="isXxsmall" v-html="label.menu" :class="$style.label" />
      <span v-else :class="$style.icon" />
    </button>
  </transition-group>
</template>

<script>
import NavBarItem from '@/components/nav-bar-item'
import { mapState } from 'vuex'
// import LanguageSwitch from '@/components/language-switch.vue'

export default {
  components: {
    NavBarItem
    // LanguageSwitch
  },
  data() {
    return {
      label: {
        menu: 'Menu'
      }
    }
  },
  computed: {
    ...mapState([
      'siteTitle',
      'claim',
      'contact',
      'apiRoutes',
      'currentLanguage',
      'isXxsmall',
      'navVisible'
    ]),
    visibleRoutes() {
      return this.apiRoutes.filter(
        item => item.template !== 'landing-page' && item.template !== 'home'
      )
    }
  },
  methods: {
    closeNav() {
      this.$store.commit('setNavVisibility', false)
    },
    openNav() {
      this.$store.commit('setNavVisibility', true)
    },
    backToHome() {
      this.closeNav()
    }
  }
}
</script>

<style lang="scss" scoped>
@include t-fade('t-fade');
</style>

<style lang="scss" module>
.component {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  width: 100%;
  // height: 100vh;
  height: 100%;
  overflow-y: auto;
  color: var(--black);
  text-transform: lowercase;
  background-color: var(--white-alpha);
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--gutter);
}

.title,
.claim,
.contact {
  @extend %ff-sans;
  @extend %fw-bold;
}

.link {
  @extend %link-reset;

  display: inline-block;
}

.title,
.claim {
  @extend %fs-medium;
}

.title {
  position: relative;
  width: calc(100% - 1.5em); // to make sure title does not overlap close button
  margin-top: -0.2em;

  @media (min-width: $medium) {
    width: calc(100% - 1em);
  }
}

// .controls {
//   display: flex;
//   align-items: flex-end;
//   justify-content: space-between;
//   pointer-events: auto;
// }

.items {
  pointer-events: auto;
}

.claim {
  margin-bottom: var(--blank-line);
  overflow-wrap: break-word;

  // @media (min-width: $xsmall) {
  //   max-width: 12em;
  // }

  @media (min-width: $medium) {
    margin-bottom: calc(var(--blank-line) / 2);
  }
}

.contact {
  @extend %fs-small;

  margin-bottom: var(--blank-line);

  p {
    a {
      @extend %link-reset;
      @extend %link-hover;
      @extend %link-default;
    }

    &:not(:last-of-type) {
      margin-bottom: var(--blank-line);
    }
  }

  @media (min-width: $medium) {
    margin-bottom: calc(var(--blank-line) / 2);
  }
}

.nav,
.close {
  @extend %ff-sans;
  @extend %fw-bold;

  display: flex;
  text-transform: uppercase;
  pointer-events: auto;
  cursor: pointer;
}

.label,
.icon {
  @extend %fs-nav;
}

.icon {
  display: inline-block;

  &::before {
    display: block;
    width: var(--gutter);
    height: var(--gutter);
    content: '';
    background-image: url('../assets/images/nav.svg');
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

.nav {
  padding-left: var(--gutter);
  margin-top: 0.15em;
  text-shadow: var(--shadow-nav);

  @media (min-width: $xxsmall) {
    margin-top: initial;
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--gutter);
}
</style>
