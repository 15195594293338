<template>
  <!-- <transition name="t-fade"> -->
  <header :class="classes.component">
    <div :class="$style.wrapper">
      <router-link to="/" :class="classes.link">{{
        siteTitle[currentLanguage]
      }}</router-link>

      <!-- portal slots for page title and project title of specific title + counter for project slideshow -->
      <portal-target name="page-title" :class="$style.pageTitle" />
      <portal-target
        v-if="isProject"
        name="project-title"
        :class="$style.projectTitle"
        multiple
      />

      <!-- category-filter button on projects -->
      <button
        v-if="buttonVisible"
        type="button"
        v-html="label.filter"
        :class="classes.button"
        @click="openCategoryFilter"
      />
    </div>
    <nav-bar />
  </header>
  <!-- </transition> -->
</template>

<script>
import NavBar from '@/components/nav-bar'
import { mapState } from 'vuex'
import EventBus from '@/event-bus'

export default {
  components: {
    NavBar
  },
  data() {
    return {
      label: {
        filter: 'filter'
      },
      buttonVisible: false,
      currentCategory: {}
    }
  },
  computed: {
    ...mapState([
      'siteTitle',
      'currentLanguage',
      'navVisible',
      'filterVisible'
    ]),
    // showFilterButton() {
    //   return this.buttonVisible
    // },
    isProject() {
      return this.$route.meta.template === 'project'
    },
    classes() {
      return {
        component: [
          this.$style.component,
          this.navVisible ? this.$style.navVisible : ''
        ],
        link: [
          this.$style.link,
          this.navVisible ? this.$style.navVisible : '',
          this.filterVisible ? this.$style.filterVisible : ''
        ],
        button: [
          this.$style.button,
          this.navVisible ? this.$style.navVisible : '',
          this.filterVisible ? this.$style.filterVisible : ''
        ]
      }
    }
  },
  methods: {
    filterLabel() {
      this.currentCategory === undefined
        ? (this.label.filter = 'filter')
        : (this.label.filter = this.currentCategory)
    },
    openCategoryFilter() {
      this.$store.commit('setFilterVisibility', true)
    }
  },
  mounted() {
    // get current category title for label of filter button
    EventBus.$on('current-category', payload => {
      this.currentCategory = payload
    })

    // listen to loading flag in projects view to handle visibility of filter button
    EventBus.$on('show-filter-button', payload => {
      this.buttonVisible = payload
    })
  },
  watch: {
    currentCategory() {
      this.filterLabel()
    }
    // filterVisible() {
    //   console.log(this.filterVisible)
    // }
  }
}
</script>

<style lang="scss" scoped>
@include t-fade;
</style>

<style lang="scss" module>
.component {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: var(--gutter);
  pointer-events: none; // workaround to make scaling of comp work

  &.navVisible {
    align-items: flex-start;
    pointer-events: auto;
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  pointer-events: auto;
}

.link,
.button,
.pageTitle,
.projectTitle {
  @extend %ff-sans;
  @extend %fw-bold;
  @extend %fs-nav;

  text-transform: uppercase;
}

.projectTitle,
.pageTitle {
  margin-right: var(--gutter);
}

.link {
  @extend %link-reset;

  margin-right: var(--gutter);
  text-transform: uppercase;
  // pointer-events: auto;
}

.link,
.button {
  text-shadow: var(--shadow-nav);
  transition: color 450ms ease, text-shadow 450ms ease;

  &.navVisible,
  &.filterVisible {
    color: transparent;
    text-shadow: 0 0 0 transparent;
  }
}
// .button {
//   color: var(--black);
//   text-transform: uppercase;
//   transition: color 450ms ease;

//   &.intro-visible.is-negative {
//     color: var(--white);
//   }

//   @media (orientation: landscape) {
//     &.is-negative {
//       color: var(--white);
//     }
//   }
// }
</style>
